import Head from "next/head";

import Home from "@/views/employer";
import React from "react";

const HomePage = () => {
  return (
    <>
      <Head>
        <title>Employer | Zelevate</title>
        <meta
          name="description"
          content="Checkout india best develop with minimum +3 year of experience"
        />
      </Head>
      <Home />
    </>
  );
};

export default HomePage;

export async function getStaticProps() {
  return {
    props: {},
  };
}
